import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import $axios from '@/core/utils/axios-api-config';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'property-details',
    components: {},
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
    },
    created() {
        this.checkSuccessfulWorkstationTransaction();
    },
    methods: {
        ...mapActions(['setPaymentSuccessfulModal']),
        async checkSuccessfulWorkstationTransaction() {
            const { id } = this.$route.params;
            const { userId } = this.authenticatedUser;
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params.session_id) {
                const url = `/payment/${userId}/session?sessionId=${params.session_id}&propertyId=${id}`;
                await $axios.get(url)
                    .then(async (sessionResponse) => {
                    const { subscription } = sessionResponse;
                    if (subscription) {
                        this.setPaymentSuccessfulModal(true);
                    }
                });
            }
        },
    }
});
